import React from "react"
import Wave from "react-wavify"

const WaveBlock = ({ fill }) => {
    return (
        <div
            style={{
                position: "absolute",
                right: 0,
                // backgroundColor: "red",
                bottom: 0,
                width: "100%",
            }}
        >
            <Wave
                fill={fill}
                paused={false}
                overflow="visible"
                style={{ overflow: "visible" }}
                options={{
                    height: 14,
                    amplitude: 40,
                    speed: 0.2,
                    points: 3,
                }}
            />
        </div>
    )
}

export default WaveBlock
